
import axios from 'axios';
export default {
    
    async createOffHourRequest(params)  {
        return await axios.post(`off_hour_request/create` , params)
    },
    async createOffHourRequestList(params)  {
        return await axios.post(`off_hour_request/create/list` , params)
    },
    async updateOffHourRequestColumn(column , value , data)  {
        return await axios.put(`off_hour_request/update_list?${column}=${value}` , data)
    },
    async deleteOffHourRequestList(list)  {
        return await axios.delete(`off_hour_request/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportOffHourRequest(column , value)  {
        return await axios.get(`off_hour_request/report?${column}=${value}`)
    },
    async getAllOffHourRequest()  {
        return await axios.get(`off_hour_request/all`)
    },
    async getOneOffHourRequest(off_hour_request_id)  {
        return await axios.get(`off_hour_request/all/${off_hour_request_id}`)
    },
    async getOffHourRequestByColumn(column , value)  {
        return await axios.get(`off_hour_request/filter?column=${column}&value=${value}`)
    },
    async deleteOffHourRequest(off_hour_request_id)  {
        return await axios.delete(`off_hour_request/delete/${off_hour_request_id}`)
    },
    async updateOffHourRequest(off_hour_request_id , params)  {
        return await axios.put(`off_hour_request/update/${off_hour_request_id}` , params)
    }
}